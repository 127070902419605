import Grid from '@mui/material/Grid';
import ACECaseListSummary from '../components/clinical/ACECaseListSummary';
import ClinicalReportStatusList from '../components/clinical/ClinicalReportStatusList';
import Exclusions from '../components/clinical/Exclusions';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { createElement } from 'react';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useGgCheckConnectionRead, useSourcesList } from '../aceapi/aceComponents';
import AnomalyReport from '../components/clinical/guru/anomaly/AnomalyReport';
import QuerySummary from '../components/clinical/guru/queries/QuerySummary';
import { Stack } from '@mui/material';
import GGTableSummary from '../components/clinical/guru/summary/GGTableSummary';
import GGAuthErrorBox from '../components/clinical/guru/GGAuthErrorBox';
import PolypAnnotationDownload from '../components/celery/PolypAnnotationDownload';
import CollapsiblePaperCard from '../components/report/CollapsiblePaperCard';

function SummaryTab() {
    const { app } = useAceApp();
    const { data: sources } = useSourcesList({});
    const dashboard_url = sources.find((source) => source.nickname === app)?.grafana_dashboard_url;

    return (
        <Stack direction='column' spacing={2}>
            <GGTableSummary />
            {dashboard_url && (
                <iframe
                    src={dashboard_url}
                    style={{ width: '100%', height: '100vh', border: 'none' }}
                    title='Summary Tab'
                />
            )}
        </Stack>
    );
}

function IssuesTab() {
    return <AnomalyReport />;
}

function QueriesTab() {
    return <QuerySummary />;
}

function CasesTab() {
    return (
        <Grid container spacing={2}>
            <CollapsiblePaperCard title='Polyp Annotations' xs={12}>
                <PolypAnnotationDownload />
            </CollapsiblePaperCard>
            <ClinicalReportStatusList />
            <ACECaseListSummary />
        </Grid>
    );
}

function ExclusionsTab() {
    return (
        <Grid container spacing={2}>
            <Exclusions />
        </Grid>
    );
}

const tabsConfig = [
    { key: '1', label: 'Summary', component: SummaryTab },
    { key: '2', label: 'Issues', component: IssuesTab },
    { key: '3', label: 'Queries', component: QueriesTab },
    { key: '4', label: 'Cases', component: CasesTab },
    { key: '5', label: 'Exclusions', component: ExclusionsTab },
];

export default function ClinicalReportSummary() {
    const [value, setValue] = React.useState('1');
    const { app } = useAceApp();
    const { data: checkData } = useGgCheckConnectionRead({ pathParams: { app } });

    return (
        <TabContext value={value}>
            <TabList onChange={(e, v) => setValue(v)} centered>
                {tabsConfig.map((tab) => (
                    <Tab key={tab.key} value={tab.key} label={tab.label} />
                ))}
            </TabList>
            {tabsConfig.map((tab) => (
                <TabPanel key={tab.key} value={tab.key}>
                    {checkData && checkData.success ? createElement(tab.component) : <GGAuthErrorBox />}
                </TabPanel>
            ))}
        </TabContext>
    );
}
